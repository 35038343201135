html, body, #root, .wrapper {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.wrapper {
  position: relative;
  overflow: hidden;
}
.half-dark {
  background-color: rgba(0,0,0,0.5);
}
.background {
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
}

@keyframes pop{
  50%  {transform: scale(1.2);}
}

.sliding{
  animation: slideInFromTop 0.5s forwards;
}

.popanim {
  animation: pop 0.3s linear 1;
}

.slideFromBot {
  animation: slideInFromBottom 0.3s forwards 1;
}

.hideOverflow {
  overflow: hidden;
}

@keyframes slideInFromTop {
  0% { transform: translateY(-1280px)}
  100% {transform: translateY(0)}
}

@keyframes slideInFromBottom {
  0% { transform: translateX(3999px) }
  100% { transform: translateX(0) }
}

@media (max-height: 500px) {
  html, body, #root, #wrapper {
    overflow: scroll !important;
  }
}